<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="circle"
      finish-button-text="Enregistrer"
      back-button-text="Précédent"
      next-button-text="Suivant"
      class="mb-3"
      @on-complete="onSubmit"
    >
      <!-- account details tab -->
      <tab-content title="Identification" :before-change="validationForm">
        <validation-observer ref="refFormObserverDetails" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Profil Details</h5>
              <small class="text-muted">
                Entrer les details de l'employé.
              </small>
            </b-col>

            <!-- Lastname -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Nom"
                rules="required"
              >
                <b-form-group label="Nom de l'employé" label-for="lastname">
                  <b-form-input
                    id="lastname"
                    v-model="employeeData.last_name"
                    :state="getValidationState(validationContext)"
                    placeholder="Nom de l'employé(e)..."
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Firstname -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Prénom de l'employé"
                rules="required"
              >
                <b-form-group label="Prénom de l'employé" label-for="firstname">
                  <b-form-input
                    id="first_name"
                    v-model="employeeData.first_name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Prénom..."
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Sex -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="sex"
                rules="required"
              >
                <b-form-group label="Sexe" label-for="sex">
                  <b-form-radio-group
                    id="gender"
                    v-model="employeeData.sex"
                    :options="genderOptions"
                    value="male"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- birth date -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Date de naissance"
                rules="required"
              >
                <b-form-group
                  label="Date de naissance"
                  label-for="birth_date"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    id="birth_date"
                    v-model="employeeData.birth_date"
                    :config="flatConfig"
                    class="form-control"
                    placeholder="Date de naissance..."
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- address -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Adresse"
                rules=""
              >
                <b-form-group label="Adresse" label-for="address">
                  <b-form-textarea
                    id="address"
                    v-model="employeeData.address"
                    :state="getValidationState(validationContext)"
                    placeholder="Adresse..."
                    rows="1"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- professional details tab -->
      <tab-content
        title="Infos Professionnelles"
        :before-change="validationFormProfessionalInfo"
      >
        <validation-observer ref="refFormObserverProfessional" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Infos Professionnelles</h5>
              <small class="text-muted"
                >Entrer les informations professionnelles de l'employé.</small
              >
            </b-col>

            <!-- job title -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Poste Occupé"
                rules="required"
              >
                <b-form-group label="Poste Occupé" label-for="job_title">
                  <b-form-input
                    id="job_title"
                    v-model="employeeData.job_title"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Poste occupé..."
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- contract type -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Contrat"
                rules="required"
              >
                <b-form-group
                  label="Contrat"
                  label-for="contract"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="employeeData.contract_type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="contractOptions"
                    :reduce="(val) => val.value"
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                    :clearable="false"
                    input-id="contract"
                    placeholder="Sélectionner le type de contrat..."
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Email -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required|email"
              >
                <b-form-group label="Email" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="employeeData.email"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="E-mail..."
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Phone -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Téléphone"
                rules=""
              >
                <b-form-group label="Téléphone" label-for="phone">
                  <b-form-input
                    id="phone"
                    v-model="employeeData.phone"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Téléphone..."
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Employee Department -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Department"
                rules="required"
              >
                <b-form-group
                  label="Department"
                  label-for="employee-department"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="employeeData.department"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="departmentOptions"
                    :reduce="(val) => val.uid"
                    label="name"
                    :clearable="false"
                    input-id="employee-department"
                    @input="getOfficeOptions"
                    placeholder="Sélectionner le département..."
                    :selectable="(option) => option.uid != null"
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      Aucun département disponible
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Employee Office -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Service"
                rules=""
              >
                <b-form-group
                  label="Service"
                  label-for="employee-office"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="employeeData.service"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="officeOptions"
                    :reduce="(val) => val.uid"
                    :clearable="true"
                    label="name"
                    input-id="employee-office"
                    placeholder="Sélectionner le service..."
                    :selectable="
                      (option) => !option.value.includes('nothing_selected')
                    "
                  >
                    <!-- eslint-disable-next-line vue/no-unused-vars  -->
                    <template #no-options="{ search, searching, loading }">
                      Aucun service disponible
                    </template></v-select
                  >
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- contract  -->
      <tab-content title="Contrat" :before-change="validationFormContract">
        <validation-observer ref="refFormObserverContract" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Contrat</h5>
              <small class="text-muted"
                >Entrer les informations du contrat</small
              >
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <!-- check if employee is contractor or agent -->
            <b-col v-if="isLimitedContract" cols="12">
              <validation-provider
                #default="validationContext"
                name="employee_type"
                rules=""
              >
                <b-form-group
                  label-for="employee_type"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-checkbox
                    v-model="employeeData.employee_type"
                    value="contractor"
                    unchecked-value="agent"
                    class="custom-control-warning"
                  >
                    <span class="text-bold">Prestataire</span>
                  </b-form-checkbox>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- field: salary -->
          <b-row class="mb-2">
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Salaire"
                rules="required"
              >
                <b-form-group
                  label-for="salary"
                  :state="getValidationState(validationContext)"
                >
                  <b-input-group prepend="XOF" append=".00">
                    <b-form-input
                      id="salary"
                      v-model="employeeData.salary"
                      type="number"
                      trim
                      placeholder="Salaire..."
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- field: grade -->
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Niveau d'Étude"
                rules="required"
              >
                <b-form-group label="Niveau d'Étude" label-for="grade">
                  <b-form-input
                    id="grade"
                    v-model="employeeData.grade"
                    trim
                    placeholder="Niveau d'Étude..."
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- hire date -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Date d'embauche"
                rules="required"
              >
                <b-form-group label="Date d'embauche" label-for="hire_date">
                  <flat-pickr
                    id="hire_date"
                    v-model="employeeData.hire_date"
                    :config="flatConfig"
                    class="form-control"
                    placeholder="Date d'embauche'..."
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- contract start date -->
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Date de début"
                rules="required"
              >
                <b-form-group
                  label="Date de début"
                  label-for="contract_start_date"
                >
                  <flat-pickr
                    id="contract_start_date"
                    v-model="employeeData.contract_start_date"
                    :config="flatConfig"
                    class="form-control"
                    placeholder="Date de début contrat..."
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- contract end date -->
            <b-col v-if="isLimitedContract" md="6">
              <validation-provider
                #default="validationContext"
                name="Date de fin"
                rules="required"
              >
                <b-form-group label="Date de fin" label-for="contract_end_date">
                  <flat-pickr
                    id="contract_end_date"
                    v-model="employeeData.contract_end_date"
                    :config="flatConfig"
                    class="form-control"
                    placeholder="Date de fin contrat..."
                    :disabled="isContractorEmployeeType"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { computed, ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import useEmployeesList from "./useEmployeesList";
import { useRouter } from "@core/utils/utils";

import moment from "moment";
moment.locale("fr");

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BRow,
    BCol,
    FormWizard,
    TabContent,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  methods: {
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.refFormObserverDetails.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormProfessionalInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.refFormObserverProfessional.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormContract() {
      return new Promise((resolve, reject) => {
        this.$refs.refFormObserverContract.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
  setup(props, { emit }) {
    const { route, router } = useRouter();
    const toast = useToast();

    const blankEmployeeData = {
      sex: "",
      last_name: "",
      first_name: "",
      email: "",
      phone: "",
      address: "",
      job_title: "",
      grade: "",
      birth_date: null,
      hire_date: null,
      salary: 0,
      department: null,
      service: null,
      employee_type: "agent",
      contract_type: "",
      contract_start_date: null,
      contract_end_date: null,
    };

    const flatConfig = ref({
      wrap: true,
      altFormat: "d-m-Y",
      dateFormat: "d-m-Y",
      // defaultDate: new Date(),
      locale: French, // locale for this instance only
    });

    const {
      departmentOptions,
      contractOptions,
      genderOptions,
      officeOptions,
      fetchEmployeeProperties,
      getOfficeOptions,
    } = useEmployeesList();

    fetchEmployeeProperties(["departments"]);

    const employeeData = ref(JSON.parse(JSON.stringify(blankEmployeeData)));
    const resetemployeeData = () => {
      employeeData.value = JSON.parse(JSON.stringify(blankEmployeeData));
    };

    const formatDate = (date) =>
      moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");

    const onSubmit = () => {
      let employee = { ...employeeData.value };

      // format birth date
      if (employee.birth_date != null)
        employee.birth_date = formatDate(employee.birth_date);

      // format hire date
      if (employee.hire_date != null)
        employee.hire_date = formatDate(employee.hire_date);

      // format contract start date
      if (employee.contract_start_date != null)
        employee.contract_start_date = formatDate(employee.contract_start_date);

      // format contract end date
      if (employee.contract_end_date != null)
        employee.contract_end_date = formatDate(employee.contract_end_date);

      store
        .dispatch("employee/addEmployee", employee)
        .then(({ success }) => {
          if (success) {
            router.push({ name: "apps-employees-list" });
            toast({
              component: ToastificationContent,
              props: {
                title: "Employé(e) enregistré avec succès.",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            throw new Error("Problème d'enregistrement de l'employé(e).");
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Problème d'enregistrement de l'employé(e)",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetemployeeData);

    // Check if employee is in limited contract to show fields (contractor field and contract end date)
    const isLimitedContract = computed(() => {
      return employeeData.value.contract_type == "cdd";
    });

    // check if employee is a contractor and be able to set contract end date automatically
    const isContractorEmployeeType = computed(() => {
      return employeeData.value.employee_type == "contractor";
    });

    const contractStartDate = computed(() => {
      return employeeData.value.contract_start_date;
    });

    watch(contractStartDate, (val) => {
      if (val != null && isContractorEmployeeType.value)
        employeeData.value.contract_end_date = moment(val, "DD-MM-YYYY")
          .add(1, "years")
          .format("DD-MM-YYYY");
    });

    watch(isContractorEmployeeType, (val) => {
      let startDate = employeeData.value.contract_start_date;
      if (val == true && startDate != null) {
        employeeData.value.contract_end_date = moment(startDate, "DD-MM-YYYY")
          .add(1, "years")
          .format("DD-MM-YYYY");
      }
    });

    return {
      flatConfig,
      employeeData,
      onSubmit,
      contractOptions,
      departmentOptions,
      officeOptions,
      genderOptions,
      isLimitedContract,
      isContractorEmployeeType,

      refFormObserver,
      getValidationState,
      resetForm,
      getOfficeOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
